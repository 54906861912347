import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import "purecss";
import "purecss/build/grids-responsive.css";
import Menu from "./menu";
import {
  mediaQueryBreakpoint,
  menuTransition,
  menuWidth,
} from "../styles/common";
import DefaultMetadata from "./metadata";

const GlobalStyle = createGlobalStyle`
  body {
    color: #737373;
  }
`;

const StyledLayout = styled.div`
  ${menuTransition};
  position: relative;
  left: 0;
  padding-left: 0;

  @media (min-width: ${mediaQueryBreakpoint}) {
    padding-left: ${menuWidth};
    left: 0;
  }

  @media (max-width: ${mediaQueryBreakpoint}) {
    ${(props) =>
      props.menuDrawerActive ? `position: relative; left: ${menuWidth};` : ""};
  }
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto 0;
  line-height: 1.6em;
  text-align: justify;
`;

const Header = styled.div`
  margin: 0;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #eee;
`;

const Main = styled.main`
  padding: 2em;

  @media (max-width: 568px) {
    padding: 1em;
  }
`;

const H1 = styled.h1`
  margin: 0.2em 0;
  font-size: 3em;
  font-weight: 300;
`;

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuDrawerActive: false };
    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
    this.handleClickOutsideMenu = this.handleClickOutsideMenu.bind(this);
  }

  handleMenuButtonClick() {
    this.setState((prevState) => ({
      menuDrawerActive: !prevState.menuDrawerActive,
    }));
  }

  handleClickOutsideMenu() {
    const { menuDrawerActive } = this.state;

    if (menuDrawerActive) {
      this.setState({ menuDrawerActive: false });
    }
  }

  render() {
    const { title, children } = this.props;
    const { menuDrawerActive } = this.state;
    return (
      <>
        <Location>
          {({ location }) => <DefaultMetadata currentUrl={location.href} />}
        </Location>
        <GlobalStyle />
        <StyledLayout menuDrawerActive={menuDrawerActive}>
          <Menu
            menuDrawerActive={menuDrawerActive}
            onMenuButtonClick={this.handleMenuButtonClick}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <Main onClick={this.handleClickOutsideMenu}>
            <Header className="header">
              <H1>{title}</H1>
            </Header>
            <Content className="content">{children}</Content>
          </Main>
        </StyledLayout>
      </>
    );
  }
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
