import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import joseGonzalezImageSource from "../images/jose-gonzalez.jpg";

const name = "Jose Gonzalez";

const Title = ({ children: title }) => {
  // This should be the same as Helmet's titleTemplate
  const fullTitle = `${title} - ${name}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="og:title" content={fullTitle} />
    </Helmet>
  );
};

const MetaDescription = ({ children: description }) => (
  <Helmet>
    <meta name="description" content={description} />
    <meta name="og:description" content={description} />
  </Helmet>
);

const DefaultMetadata = ({ currentUrl }) => {
  const description = `${name}'s personal website. Jose is a software engineer from Spain that now lives in the UK, and specialises in web development.`;

  return (
    <Helmet defaultTitle={name} titleTemplate={`%s - ${name}`}>
      <html lang="en" />
      <meta name="description" content={description} />
      <meta name="author" content={name} />
      <meta name="og:title" content={name} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={currentUrl} />
      {/* Use the original image (rather than a small, optimised one), as recommended by Facebook */}
      <meta name="og:image" content={joseGonzalezImageSource} />
      <meta name="og:image:alt" content="Jose Gonzalez" />
      <meta name="og:description" content={description} />
      <meta name="og:locale" content="en_GB" />
      <meta name="og:site_name" content={name} />
    </Helmet>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

MetaDescription.propTypes = {
  children: PropTypes.string.isRequired,
};

DefaultMetadata.propTypes = {
  currentUrl: PropTypes.string.isRequired,
};

export default DefaultMetadata;
export { MetaDescription, Title };
