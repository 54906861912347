import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

const H2 = styled.h2`
  font-weight: 300;
  color: #888;
`;

const UniversalLink = ({ children, to, ...other }) => {
  // Any internal link (intended for Gatsby) will start with exactly one slash, and anything else is external
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <GatsbyLink to={to} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={to} {...other}>
      {children}
    </a>
  );
};

const StyledLink = styled(UniversalLink)`
  color: #007bad;
  text-decoration: none;
`;

UniversalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export { H2, StyledLink as Link };
