import React from "react";
import PropTypes from "prop-types";
import { Location } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import FileTextIcon from "react-feather/dist/icons/file-text";
import HomeIcon from "react-feather/dist/icons/home";
import GithubIcon from "react-feather/dist/icons/github";
import ImageIcon from "react-feather/dist/icons/image";
import LinkedInIcon from "react-feather/dist/icons/linkedin";
import MailIcon from "react-feather/dist/icons/mail";
import ToggleMenuButton from "./toggle-menu-button";
import { Link } from "./basic";
import {
  mediaQueryBreakpoint,
  menuTransition,
  menuWidth,
} from "../styles/common";

const internalLinks = [
  { name: "CV", path: "/cv/", icon: FileTextIcon },
  { name: "Portfolio", path: "/portfolio/", icon: ImageIcon },
  { name: "Contact", path: "/contact/", icon: MailIcon },
];

const externalLinks = [
  { name: "Github", path: "https://github.com/jmgq", icon: GithubIcon },
  {
    name: "LinkedIn",
    path: "https://www.linkedin.com/in/josegonzalezjmgq",
    icon: LinkedInIcon,
  },
];

const StyledIconWrapper = styled.span`
  svg {
    height: 16px;
    width: 16px;
    position: relative;
    top: 2px;
  }
`;

const TextWithIcon = ({ icon, children }) => {
  const Icon = icon;

  return (
    <>
      <StyledIconWrapper>
        <Icon />
      </StyledIconWrapper>
      {` ${children}`}
    </>
  );
};

const StyledLink = styled(Link)`
  &&& {
    color: #999;
    border: none;
    padding: 0.6em 0 0.6em 0.6em;
    :hover,
    :focus {
      background: #333;
    }
  }
`;

const MenuItemLink = ({ children, to, className }) => (
  <StyledLink to={to} className={`pure-menu-link ${className}`}>
    {children}
  </StyledLink>
);

const SelectedMenuItemLink = styled(MenuItemLink)`
  &&&& {
    color: #fff;
  }
`;

const MainLink = ({ className }) => (
  <StyledLink to="/" className={`pure-menu-heading ${className}`}>
    <TextWithIcon icon={HomeIcon}>Home</TextWithIcon>
  </StyledLink>
);

const StyledMainLink = styled(MainLink)`
  &&& {
    font-size: 110%;
    color: #fff;
    margin: 0;
  }
`;

const SelectedMenuItem = ({ to, children, className }) => (
  <li className={`pure-menu-item pure-menu-selected ${className}`}>
    <SelectedMenuItemLink to={to}>{children}</SelectedMenuItemLink>
  </li>
);

const StyledSelectedMenuItem = styled(SelectedMenuItem)`
  background: #007cc0;
`;

const UnselectedMenuItem = ({ to, children }) => (
  <li className="pure-menu-item">
    <MenuItemLink to={to}>{children}</MenuItemLink>
  </li>
);

const MenuItem = ({ path, children, active }) => {
  if (active) {
    return (
      <StyledSelectedMenuItem to={path}>{children}</StyledSelectedMenuItem>
    );
  }

  return <UnselectedMenuItem to={path}>{children}</UnselectedMenuItem>;
};

const MenuItemList = ({ children: sections, currentPathName, className }) => {
  const menuItems = sections.map((section) => (
    <MenuItem
      key={section.path}
      path={section.path}
      active={currentPathName === section.path}
    >
      <TextWithIcon icon={section.icon}>{section.name}</TextWithIcon>
    </MenuItem>
  ));

  return <ul className={`pure-menu-list ${className}`}>{menuItems}</ul>;
};

const StyledMenuItemList = styled(MenuItemList)`
  border: none;
  background: transparent;
  border-top: 1px solid #333;
`;

const StyledBottomMenuItemList = styled(StyledMenuItemList)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const MenuWrapper = styled.nav`
  ${menuTransition};
  margin-left: -${menuWidth};
  width: ${menuWidth};
  position: fixed;
  top: 0;
  left: ${(props) => (props.menuDrawerActive ? menuWidth : 0)};
  bottom: 0;
  z-index: 1000;
  background: #191818;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${mediaQueryBreakpoint}) {
    left: ${menuWidth};
  }
`;

const BaseMenu = ({ className, children }) => (
  <div className={`pure-menu ${className}`}>{children}</div>
);

const StyledBaseMenu = styled(BaseMenu)`
  border: none;
  background: transparent;
`;

const MenuComponent = ({ menuDrawerActive, onMenuButtonClick }) => (
  <>
    <ToggleMenuButton
      menuDrawerActive={menuDrawerActive}
      onClick={onMenuButtonClick}
    />
    <MenuWrapper
      id="menu"
      menuDrawerActive={menuDrawerActive}
      role="navigation"
    >
      <StyledBaseMenu>
        <StyledMainLink />
        <Location>
          {({ location }) => (
            <>
              <StyledMenuItemList currentPathName={location.pathname}>
                {internalLinks}
              </StyledMenuItemList>
              <StyledBottomMenuItemList currentPathName={location.pathname}>
                {externalLinks}
              </StyledBottomMenuItemList>
            </>
          )}
        </Location>
      </StyledBaseMenu>
    </MenuWrapper>
  </>
);

TextWithIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.string.isRequired,
};

MenuItemLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

MenuItemLink.defaultProps = {
  className: null,
};

MainLink.propTypes = {
  className: PropTypes.string.isRequired,
};

MenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

MenuItem.defaultProps = {
  active: false,
};

UnselectedMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

SelectedMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
};

MenuItemList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  currentPathName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

BaseMenu.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

MenuComponent.propTypes = {
  menuDrawerActive: PropTypes.bool.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
};

export default MenuComponent;
