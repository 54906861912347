import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  mediaQueryBreakpoint,
  menuTransition,
  menuWidth,
} from "../styles/common";

const line = `
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
`;

const Inner = styled.span`
  display: block;
  top: 50%;
  margin-top: -2px;
  ${line};

  ${(props) => props.menuDrawerActive && "transform: rotate(45deg);"};

  ::before,
  ::after {
    ${line};
    content: "";
    display: block;
  }

  ::before {
    top: -10px;
    ${(props) => props.menuDrawerActive && "top: 0; opacity: 0;"};
  }

  ::after {
    bottom: -10px;
    ${(props) =>
      props.menuDrawerActive && "bottom: 0; transform: rotate(-90deg);"};
  }
`;

const Box = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const Hamburger = ({ className, onClick, menuDrawerActive }) => (
  <button
    type="button"
    className={className}
    onClick={onClick}
    aria-label="Open/Close Menu"
    aria-controls="menu"
    aria-expanded={menuDrawerActive.toString()}
  >
    <Box>
      <Inner menuDrawerActive={menuDrawerActive} />
    </Box>
  </button>
);

const StyledToggleMenuButton = styled(Hamburger)`
  position: fixed;
  z-index: 10;
  left: ${(props) => (props.menuDrawerActive ? menuWidth : 0)};
  ${menuTransition};
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: rgba(7, 7, 7, 0.3);
  border: 0;
  margin: 0;
  overflow: visible;

  @media (min-width: ${mediaQueryBreakpoint}) {
    left: ${menuWidth};
    display: none;
  }
`;

Hamburger.propTypes = {
  className: PropTypes.string.isRequired,
  menuDrawerActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StyledToggleMenuButton;
